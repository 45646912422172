import { ref, onMounted, onBeforeUnmount } from '#imports';
import { throttle } from 'lodash-es';

const mobileBreakpoint = 768;
// @TODO return 44px then pride is over
const mainHeaderHeightOnMobile = 44;

export function useAnimatedHeader(headerElement) {
  const lastScrollTop = ref(0);

  function onScroll() {
    if (!headerElement.value) return;

    const scrollTopOffset = window.pageYOffset || document.body.scrollTop;
    const isScrollingTop = scrollTopOffset > lastScrollTop.value;
    const isMobile = window.innerWidth < mobileBreakpoint;

    // eslint-disable-next-line no-param-reassign
    headerElement.value.style.top =
      isScrollingTop && isMobile ? `-${mainHeaderHeightOnMobile}px` : '0';

    lastScrollTop.value = scrollTopOffset <= 0 ? 0 : scrollTopOffset;
  }

  const throttledOnScroll = throttle(onScroll, 300);

  onMounted(() => {
    document.addEventListener('scroll', throttledOnScroll);
  });

  onBeforeUnmount(() => {
    document.removeEventListener('scroll', throttledOnScroll);
  });
}
